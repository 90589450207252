import { useSelector } from "react-redux"

import RenderCartCourses from "./RenderCartCourses"
import RenderTotalAmount from "./RenderTotalAmount"

export default function Cart() {
  const { total, totalItems } = useSelector((state) => state.cart)
  const { paymentLoading } = useSelector((state) => state.course)

  if (paymentLoading)
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="spinner"></div>
      </div>
    )

  return (
    <>
    <h1 className="mb-4 md:mb-8 lg:mb-14 text-3xl font-medium text-richblack-5">Cart</h1>
    <p className="border-b border-b-richblack-400 pb-2 font-semibold text-richblack-400">
      {totalItems} Courses in Cart
    </p>
    {total > 0 ? (
      <div className="mt-8 md:flex-col lg:flex-row items-start gap-x-10 gap-y-6 sm:gap-x-2">
        <RenderCartCourses />
        <RenderTotalAmount />
      </div>
    ) : (
      <p className="mt-4 md:mt-8 lg:mt-14 text-center text-3xl text-richblack-100">
        Your cart is empty
      </p>
    )}
  </>
  )
}
